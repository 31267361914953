import { createI18n } from 'vue-i18n'
import { Locale } from 'vant';
import enLocale from './en.json'
import zhLocale from './zh.json'
import hiLocale from './hi.json'
import idLocale from './id.json'
// 引入vant语言包

import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import hiIN from 'vant/es/locale/lang/hi-IN';
import idID from 'vant/es/locale/lang/id-ID';

const LOCALE_KEY = 'language'; // localStorage来存放的key，名字随便定，接下来会用到。
const DEFAULT_LANG = getUrlParam('lang') || 'en';
// const DEFAULT_LANG = window.localStorage.getItem(LOCALE_KEY) || 'zh';

// 获取URL参数
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

const locales = { // 引入zh.json以及es.json
    zh: {
      ...zhLocale,
      ...zhCN
    },
    en: {
      ...enLocale,
      ...enUS
    },
    hi: {
      ...hiLocale,
      ...hiIN
    },
    id: {
      ...idLocale,
      ...idID
    },
  };

const i18n = createI18n({
    locale: DEFAULT_LANG,
    messages: locales
})
export const setup = lang => { // 切换语言的函数，lang为语言标识
    // 在此判断lang的值，如果未定义，则让lang默认为DEFAULT_LANG，目的是为了让用户在未选择语言的时候默认为巴西(葡萄牙语)。
      if (lang == undefined) {
      lang = window.localStorage.getItem(LOCALE_KEY);
      if (locales[lang] == undefined) {
        lang = DEFAULT_LANG;
      }
    }
    // 若lang有值，那么存入localStorage中，key为LOCALE_KEY,value为lang。
    window.localStorage.setItem(LOCALE_KEY, lang);
    // vant
    Locale.use(lang, locales[lang])
    i18n.locale = lang;
    console.log(lang);
  };
  setup();
export default i18n;