import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';

// 国际化
import i18n from './locales';
const app = createApp(App);
// vant组件
import 'vant/lib/index.css'; // 引入 Vant 的样式文件
import { Lazyload } from 'vant';
// 引入模块后自动生效（兼容pc）
import '@vant/touch-emulator';

import VConsole from 'vconsole';
// const vConsole = new VConsole();

// 按钮点击反馈效果
import clickFeedback from './directives/clickFeedback';
app.directive('click-feedback', clickFeedback);

// 自定义组件
import NavBar from '@/components/NavBar/index.vue';
import Empty from '@/components/Empty/index.vue';
import TopBar from '@/components/TopBar/index.vue';

app.component('NavBar', NavBar).component('Empty', Empty).component('TopBar', TopBar);
// 自定义toast
import showToast from './components/Toast/toast.js';
app.config.globalProperties.$toast = showToast;

import axios from './util/axios';
app.config.globalProperties.$axios = axios;

// 注册时可以配置额外的选项
const loadimage = require('./assets/loading_bg.png')
app.use(Lazyload, {
  loading: loadimage,
});

app.use(store)
app.use(i18n)
app.use(router);
app.mount('#app');
